@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins&display=swap");

.nav-bg {
  background-color: rgb(5, 14, 33);
}

.land-home-head {
  font-family: "Poppins", sans-serif;
}

.land-home-height {
  height: calc(100vh - 85px);
}

.bg-color {
  background-color: rgb(229, 239, 255);
}

.bg-color-2 {
  background-color: rgb(243, 247, 255);
}

.text-heading {
  color: rgb(220, 228, 252);
}

.accordion-header {
  transition: 1s ease-in-out;
}

.ReactCollapse--collapse {
  transition: height 400ms;
}

textarea {
  resize: none;
}

input::placeholder,
textarea::placeholder {
  color: rgb(0, 0, 0, 0.7);
  font-size: medium;
}

input:focus,
textarea:focus {
  outline: none;
  /* border: 1px solid #fc0235; */
  background-color: white;
}

.nav-list-option::after {
  content: "";
  border: 2px solid red;
  display: flex;
  width: 0%;
  margin-top: 5px;
  visibility: hidden;
  transition: 0.4s ease-in-out;
}

.nav-list-option:hover::after {
  visibility: visible;
  width: 100%;
}

.long-hire-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(252, 2, 53, 0.5);
  width: 100%;
  z-index: -1;
  visibility: visible;
  transition: 0.5s all ease-in;
}

.long-hire-button:hover::after {
  width: 0%;
  visibility: hidden;
}

.long-hire-button-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fc0235;
  width: 100%;
  z-index: -1;
  visibility: visible;
  transition: 0.5s all ease-in;
}

.long-hire-button-2:hover::after {
  width: 0%;
  visibility: hidden;
}

.quartz-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0%;
  z-index: -1;
  visibility: hidden;
  transition: 0.5s all ease-in;
}

.quartz-btn:hover::after {
  width: 100%;
  visibility: visible;
  background-color: rgba(0, 0, 255, 0.9);
}

.view-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  visibility: hidden;
  transition: 0.5s all ease-in;
  width: 0%;
}

.view-btn:hover::after {
  width: 100%;
  visibility: visible;
  background-color: #d61313;
}

.bg-attach {
  background-color: rgba(0, 0, 0, 0.5);
}

.why-quartz-pc {
  background-image: url("./images/why-quartz.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.why-quartz-dbs {
  background-image: url("./images/mobile-quartz.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.button-bg-color {
  background-color: rgba(252, 2, 53, 0.5);
}

.my-theme-color {
  color: #fc0235;
}

.center-div {
  position: absolute;
  transform: translate(-50%, -50%);
}

html {
  scroll-behavior: smooth;
}

.learn-button::before {
  content: "";
  border: 4px dashed red;
  position: absolute;
  top: 40%;
  left: 10%;
  transform: translate(-50%, -50%);
  padding: 18px;
  border-radius: 100%;
  animation: rotate 1s linear infinite;
  visibility: hidden;
}

.learn-div-button:hover .learn-button::before {
  visibility: visible;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
